import http from "@/common/js/http";

/**
 * 投诉列表
 * @param data
 */
export const getBmsComplaintListApi = async (data) => {
    return await http(`/galaxyOrder/getBmsComplaintList`, data, 'POST');
};

/**
 * 投诉回访
 * @param data
 */
export const complaintContactApi = async (data) => {
    return await http(`/galaxyOrder/complaintContact`, data, 'POST');
};

/**
 * 投诉处理完成
 * @param data
 */
export const complaintResultApi = async (data) => {
    return await http(`/galaxyOrder/complaintResult`, data, 'POST');
};

/**
 * 投诉详情
 * @param data
 */
export const getComplaintDetailApi = async (id) => {
    return await http(`/galaxyOrder/getComplaintDetail/${id}`);
};
