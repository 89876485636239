<style lang="scss" scoped>
.common-list-search {
    &::v-deep {
        .list-search-before .el-form-item.el-form-item--small {
            width: auto;
        }
    }
}
</style>
<template>
    <el-main class="com-eTrip-section">
        <comListSearch>
            <div slot="list-search-before">
                <el-form inline size="small">
                    <el-form-item>
                        <el-radio-group v-model="form.complaintStatus">
                            <el-radio-button :label="0">全部</el-radio-button>
                            <el-radio-button v-for="vm in statusOptions" :key="vm.value" :label="vm.value">
                                {{ vm.label }}
                            </el-radio-button>
                        </el-radio-group>
                    </el-form-item>
                    <el-form-item>
                        <el-input placeholder="订单号" v-model="form.orderNo" clearable></el-input>
                    </el-form-item>
                </el-form>
            </div>
            <div slot="list-search-after">
                <el-button type="primary" :loading="loading" @click="search()">搜索</el-button>
                <el-button type="primary" plain :loading="loading" @click="reset()">重置</el-button>
            </div>
        </comListSearch>
        <div class="eTrip-section-cont">
            <el-table :data="tabData.data" border>
                <el-table-column prop="orderId" label="投诉日期">
                    <template slot-scope="scope">
                        {{ scope.row.createDate | dateCus('yyyy-MM-dd hh:mm') }}
                    </template>
                </el-table-column>
                <el-table-column prop="complaintType" label="投诉类型">
                    <template slot-scope="scope">
                        {{ scope.row.complaintType | formatDesc(complaintTypeOptions) }}
                    </template>
                </el-table-column>
                <el-table-column prop="orderNo" label="订单号">
                    <template slot-scope="scope">
                        <el-link type="primary" :href="'#/userCarList?orderNo='+scope.row.orderNo">{{
                                scope.row.orderNo
                            }}
                        </el-link>
                    </template>
                </el-table-column>
                <el-table-column prop="carNum" label="车辆车牌"></el-table-column>
                <el-table-column label="司机姓名手机">
                    <template slot-scope="scope">
                        <div>
                            <p v-text="scope.row.driverName"></p>
                            <p v-text="scope.row.driverPhone"></p>
                        </div>
                    </template>
                </el-table-column>
                <el-table-column label="投诉状态">
                    <template slot-scope="scope">
                    <span :class="scope.row.complaintStatus | formatDesc(statusOptions,'value','color')">
                    {{ scope.row.complaintStatus | formatDesc(statusOptions) }}
                    </span>
                    </template>
                </el-table-column>
                <el-table-column label="操作">
                    <template slot-scope="scope">
                        <el-link class="mr10" @click="next(scope.row,0)" type="primary" size="small">查看</el-link>
                        <el-link v-if="scope.row.complaintStatus===1" @click="next(scope.row,1)" type="warning"
                                 size="small">
                            处理
                        </el-link>
                        <el-link v-if="scope.row.complaintStatus===2" @click="next(scope.row,2)" type="warning"
                                 size="small">结束
                        </el-link>
                    </template>
                </el-table-column>
            </el-table>
        </div>
        <el-footer>
            <div style="display: flex;justify-content: flex-end;margin-top: 10px">
                <el-pagination class="pagination-info" @current-change="handlePageChange" :current-page="form.pageIndex"
                               :page-size="form.pageSize" layout="total, prev, pager, next"
                               :total="tabData.total"></el-pagination>
            </div>
        </el-footer>
    </el-main>
</template>

<script type="text/ecmascript-6">

import comListSearch from "@/www/components/comListSearch/index.vue";
import {complaintTypeOptions, orderComplaintsStatusOptions} from '@/data/orderComplaints'
import {getBmsComplaintListApi} from "@/www/urls/orderComplaints";

export default {
    //定义模版数据
    data() {
        return {
            loading: false,
            form: {
                pageSize: 10,
                pageIndex: 1,
                complaintStatus: 0
            },
            tabData: {
                data: [],
                total: 0
            },
            visible: false,
            statusOptions: orderComplaintsStatusOptions,
            complaintTypeOptions
        }
    },
    components: {comListSearch},
    //计算属性
    computed: {},
    //主件被加载完成
    mounted: function () {
        this.search()
    },
    //定义事件方法
    methods: {
        async search() {
            try {
                this.loading = true
                const ret = await getBmsComplaintListApi({
                    ...this.form,
                    complaintStatus: this.form.complaintStatus ? this.form.complaintStatus : undefined,
                    orderNo: this.form.orderNo ? this.form.orderNo : undefined
                })
                if (ret.success) {
                    this.tabData = ret.data
                } else this.$message.error(ret.errors[0].message)
            } finally {
                this.loading = false
            }
        },
        reset() {
            this.form.pageIndex = 1;
            this.form.orderNo = undefined;
            this.form.complaintStatus = undefined;
            this.search()
        },
        handlePageChange(page) {
            this.form.pageIndex = page
            this.search();
        },
        next(record, type) {
            this.$router.push(`/orderComplaintsDetail?id=${record.id}&type=${type}`)
        }
    }

}
</script>
