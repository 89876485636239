export const orderComplaintsStatusOptions = [
    {
        label: '待处理',
        value: 1,
        color: 'color-danger'
    },
    {
        label: '处理中',
        value: 2,
        color: 'color-primary'
    },
    {
        label: '已处理',
        value: 3
    }
]

export const complaintTypeOptions = [
    {
        label: '司机或车辆信息不符',
        value: 1
    },
    {
        label: '司机绕路',
        value: 2
    },
    {
        label: '司机不文明驾驶',
        value: 3
    },
    {
        label: '司机态度/车内环境不佳',
        value: 4
    },
    {
        label: '失物找回',
        value: 5
    },
    {
        label: '开票问题',
        value: 6
    },
    {
        label: '其他问题',
        value: 7
    }
]
